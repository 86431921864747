"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLikedPostsFromLocalStorage = getLikedPostsFromLocalStorage;
exports.getLikedPostLength = getLikedPostLength;
exports.generateEncodedLikedPostsParam = generateEncodedLikedPostsParam;
exports.decodeLikedPosts = decodeLikedPosts;
exports.removePreloaders = removePreloaders;
exports.noPostsFound = noPostsFound;
function getLikedPostsFromLocalStorage() {
    const likedPostsJson = localStorage.getItem('liked-posts');
    return likedPostsJson ? JSON.parse(likedPostsJson) : [];
}
function getLikedPostLength() {
    return getLikedPostsFromLocalStorage().length;
}
function generateEncodedLikedPostsParam() {
    const likedPosts = getLikedPostsFromLocalStorage();
    if (likedPosts.length == 0) {
        return false;
    }
    const compactLikedPosts = likedPosts.reduce((acc, post) => {
        if (!acc[post.type]) {
            acc[post.type] = [];
        }
        acc[post.type].push(post.id);
        return acc;
    }, {});
    const encodedLikedPosts = btoa(JSON.stringify(compactLikedPosts));
    return '?liked-posts=' + encodedLikedPosts;
}
function decodeLikedPosts(encodedLikedPosts) {
    const decodedLikedPosts = atob(encodedLikedPosts);
    const compactLikedPosts = JSON.parse(decodedLikedPosts);
    const likedPosts = Object.entries(compactLikedPosts).reduce((acc, [type, ids]) => {
        ids.forEach((id) => {
            acc.push({ id, type });
        });
        return acc;
    }, []);
    return likedPosts;
}
function removePreloaders(container) {
    container.querySelectorAll('.liked-posts__preloader').forEach((preloader) => {
        preloader.remove();
    });
}
function noPostsFound(container) {
    var _a;
    (_a = container.querySelector('[data-js-no-posts-notice]')) === null || _a === void 0 ? void 0 : _a.classList.remove('u-display--none');
}
