"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const likeHelpers_1 = require("./helpers/likeHelpers");
class Share {
    constructor(container) {
        this.container = container;
        this.init();
    }
    init() {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedLikedPosts = urlParams.get('liked-posts');
        if (!encodedLikedPosts) {
            this.renderShareLink();
        }
    }
    renderShareLink() {
        const url = window.location.href.split('?')[0];
        const encodedLikedPostsParam = (0, likeHelpers_1.generateEncodedLikedPostsParam)();
        if (!encodedLikedPostsParam)
            return;
        const shareLink = `${url}${encodedLikedPostsParam}`;
        const button = this.container.querySelector('button');
        const dialog = this.container.querySelector('dialog');
        if (!dialog)
            return;
        const urlField = dialog.querySelector('[data-js-like-share-url]');
        const nameField = dialog.querySelector('[data-js-like-share-name]');
        const excerptField = dialog.querySelector('[data-js-like-share-excerpt]');
        urlField.value = shareLink;
        nameField.addEventListener('input', this.updateShareLink.bind(this, urlField, nameField, excerptField));
        excerptField.addEventListener('input', this.updateShareLink.bind(this, urlField, nameField, excerptField));
        button.classList.remove('u-display--none');
    }
    updateShareLink(urlField, nameField, excerptField) {
        const newName = btoa(nameField.value);
        const newExcerpt = btoa(excerptField.value);
        const url = new URL(urlField.value);
        if (url) {
            url.searchParams.set('liked-name', newName);
            url.searchParams.set('liked-excerpt', newExcerpt);
            if (!newName) {
                url.searchParams.delete('liked-name');
            }
            if (!newExcerpt) {
                url.searchParams.delete('liked-excerpt');
            }
        }
        urlField.value = url.href;
    }
}
exports.default = Share;
