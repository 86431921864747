"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const likeHelpers_1 = require("./helpers/likeHelpers");
class GetPosts {
    constructor(container) {
        this.container = container;
    }
    getPosts() {
        const urlParams = new URLSearchParams(window.location.search);
        const sharedPosts = urlParams.get('liked-posts');
        const posts = sharedPosts ? (0, likeHelpers_1.decodeLikedPosts)(sharedPosts) : (0, likeHelpers_1.getLikedPostsFromLocalStorage)();
        return this.fetchPosts(this.filterPosts(posts));
    }
    fetchPosts(postIds) {
        let posts = postIds.map((post) => {
            return post.id;
        });
        return posts.length > 0 ? this.getPostsFromEndpoint(posts.join(',')) : null;
    }
    getPostsFromEndpoint(posts) {
        const url = window.location.origin;
        return new Promise((resolve, reject) => {
            fetch(`${url}/wp-json/like/v1/id=${posts}`)
                .then(response => {
                if (!response.ok) {
                    return null;
                }
                return response.json();
            }).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
    filterPosts(posts) {
        if (this.container.hasAttribute('data-js-like-posts-post-types')) {
            const postTypes = JSON.parse(this.container.getAttribute('data-js-like-posts-post-types') || '[]');
            posts = posts.filter((post) => {
                return postTypes.includes(post.type);
            });
        }
        return posts;
    }
}
exports.default = GetPosts;
