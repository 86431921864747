"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const likeHelpers_1 = require("./helpers/likeHelpers");
class Render {
    constructor(posts, components, container) {
        this.container = container;
        this.components = components;
        this.posts = posts;
        if (!this.posts || this.posts.length === 0 || !components)
            return;
        this.renderComponents();
    }
    renderComponents() {
        const containers = this.container.querySelectorAll('[js-like-container]');
        if (containers) {
            containers.forEach((container) => {
                var _a, _b;
                const component = (_a = container.getAttribute('js-display-as')) !== null && _a !== void 0 ? _a : "";
                const postColumns = (_b = container.getAttribute('js-columns')) !== null && _b !== void 0 ? _b : 'grid-md-12';
                const emblemUrl = container.hasAttribute('js-like-emblem-url') ? container.getAttribute('js-like-emblem-url') || false : false;
                this.handleSharedParams(container);
                this.posts &&
                    this.posts.forEach((post) => {
                        var _a;
                        const childElement = document.createElement('div');
                        childElement.classList.add(postColumns);
                        const html = this.components[`${component}`].html
                            .replace('{LIKE_POST_TITLE}', post.postTitle)
                            .replace('{LIKE_POST_CONTENT}', post.excerptShorter)
                            .replace('{LIKE_POST_ID}', post.id.toString())
                            .replace('{LIKE_POST_LINK}', post.permalink)
                            .replace('{LIKE_POST_IMAGE}', ((_a = post.images['thumbnail1:1']) === null || _a === void 0 ? void 0 : _a.src) ? post.images['thumbnail1:1'].src : (emblemUrl !== null && emblemUrl !== void 0 ? emblemUrl : ''))
                            .replace('{LIKE_POST_TYPE}', post.postType)
                            .replace('{LIKE_POST_CLASSES}', postColumns);
                        childElement.innerHTML = html;
                        container.appendChild(childElement);
                        (0, likeHelpers_1.removePreloaders)(this.container);
                    });
            });
        }
        else {
            (0, likeHelpers_1.removePreloaders)(this.container);
        }
    }
    handleSharedParams(container) {
        const urlParams = new URLSearchParams(window.location.search);
        const title = this.container.querySelector('[data-js-liked-posts-share-title]');
        const excerpt = this.container.querySelector('[data-js-liked-posts-share-excerpt]');
        let listName = urlParams.get('liked-name');
        let listExcerpt = urlParams.get('liked-excerpt');
        if (title && listName) {
            title.textContent = this.controlURLParameters(listName);
            title.classList.remove('u-display--none');
        }
        if (excerpt && listExcerpt) {
            excerpt.textContent = this.controlURLParameters(listExcerpt);
            excerpt.classList.remove('u-display--none');
        }
        if (excerpt && listExcerpt || title && listName) {
            container.classList.add('u-margin__top--3');
        }
    }
    controlURLParameters(encodedString) {
        let string = atob(encodedString);
        string = string.replace(/(<([^>]+)>)/gi, '');
        return string;
    }
}
exports.default = Render;
