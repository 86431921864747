"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeLikedCounter = initializeLikedCounter;
const likeHelpers_1 = require("./helpers/likeHelpers");
class LikedCounter {
    constructor(counterIcons, counterComponent) {
        this.counterElements = this.addCounterToIcon(counterIcons, counterComponent);
        this.updateCounter();
    }
    addCounterToIcon(counterIcons, counterComponent) {
        const icons = counterIcons.map((counterIcon) => {
            counterIcon.innerHTML += counterComponent.html;
            return counterIcon.querySelector('[data-js-like-counter]');
        });
        return icons;
    }
    updateCounter() {
        const likedPostsLength = (0, likeHelpers_1.getLikedPostLength)();
        this.counterElements.forEach((counterElement) => {
            if (counterElement) {
                counterElement.innerHTML = likedPostsLength.toString();
            }
            if (likedPostsLength === 0) {
                counterElement === null || counterElement === void 0 ? void 0 : counterElement.classList.add('u-display--none');
            }
            else {
                counterElement === null || counterElement === void 0 ? void 0 : counterElement.classList.remove('u-display--none');
            }
        });
    }
}
exports.default = LikedCounter;
function initializeLikedCounter(counterComponent) {
    const counterIcons = document.querySelectorAll('[data-js-like-icon-counter]');
    if (counterIcons.length === 0) {
        return;
    }
    ;
    const likeCounterInstance = new LikedCounter([...counterIcons], counterComponent);
    window.addEventListener('likedPostsLengthUpdated', (e) => {
        likeCounterInstance.updateCounter();
    });
}
